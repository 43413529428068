import { App } from '../types/app.types';

export const getNavAppUrl = (app: App) => {
  const env = window === undefined ? process.env : window.env;

  if (app === 'platform') {
    return env.PLATFORM_URL;
  } else if (app === 'aim') {
    return `${env.AIM_URL}`;
  } else if (app === 'chat') {
    return `${env.ACCESS_GPT_URL}`;
  } else if (app === 'psf') {
    return `${env.PSF_URL}`;
  }

  return '';
};
