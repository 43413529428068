import { NavIconLink } from './nav-icon-link';
import { NavDropdownSeparator } from './nav-dropdown-separator';

interface Props {
  appName: string;
}

export const NavPlatformAdminMenu = ({ appName }: Props) => (
  <>
    <div className="mb-psc-sm">
      <div className="pb-psc-sm pl-psc-base pt-psc-base text-xs font-medium uppercase leading-none text-gray-800">
        {appName} ADMIN
      </div>
      <NavIconLink
        aClassName="pl-3 py-1 space-y-0"
        color="gray"
        href={window.env.PLATFORM_URL + '/admin/config'}
        icon="fa-gears text-base"
      >
        Configs
      </NavIconLink>
      <NavIconLink
        aClassName="pl-3 py-1 space-y-0"
        color="gray"
        href={window.env.PLATFORM_URL + '/admin/roles'}
        icon="fa-user-tag text-base"
      >
        Roles
      </NavIconLink>
      <NavIconLink
        aClassName="pl-3 py-1 space-y-0"
        color="gray"
        href={window.env.PLATFORM_URL + '/admin/users'}
        icon="fa-users text-base"
      >
        Users
      </NavIconLink>
      <NavIconLink
        aClassName="pl-3 py-1 space-y-0"
        color="gray"
        href={window.env.PLATFORM_URL + '/admin/brands'}
        icon="fa-copyright text-base"
      >
        Brands
      </NavIconLink>
      <NavIconLink
        aClassName="pl-3 py-1 space-y-0"
        color="gray"
        href={window.env.PLATFORM_URL + '/admin/activity'}
        icon="fa-wave-pulse text-base"
      >
        Activity
      </NavIconLink>
    </div>
    <NavDropdownSeparator />
  </>
);
