import { DetailedHTMLProps, ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';

import { classNames } from '../../helpers/classNames';

interface Props extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  hasError?: boolean;
  disabled?: boolean;
  classNameTextArea?: string;
  classNameRoundedStyle?: string;
}

export const FormTextArea = forwardRef((props: Props, ref?: ForwardedRef<HTMLTextAreaElement>) => {
  const { className, hasError, classNameTextArea, classNameRoundedStyle, disabled, ...inputProps } = props;

  return (
    <div className={classNames('relative', className)}>
      <textarea
        ref={ref}
        readOnly={disabled}
        className={classNames(
          'block w-full py-2 pl-3 pr-10 focus:outline-none sm:text-sm',
          'rounded-md border border-gray-200 shadow-sm',
          classNameRoundedStyle,
          hasError
            ? 'border-red-300 placeholder-red-400 focus:border-red-500 focus:ring-red-500'
            : 'border-gray-300 placeholder-gray-400 focus:border-blue-600 focus:ring-blue-700',
          disabled
            ? 'readonly cursor-not-allowed bg-gray-100 text-gray-500'
            : hasError
              ? 'bg-white text-red-900'
              : 'bg-white text-gray-900',
          classNameTextArea,
        )}
        aria-invalid={hasError}
        aria-describedby={`${inputProps.name}-error`}
        {...inputProps}
      />
      {hasError && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <i className="fa-solid fa-warning fa-xl text-red-300" aria-hidden="true" />
        </div>
      )}
    </div>
  );
});

FormTextArea.displayName = 'FormTextArea';
