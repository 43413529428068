import { Description, Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, ReactNode, useEffect } from 'react';

import { classNames } from '../../helpers/classNames';

interface Props {
  title?: ReactNode;
  description?: ReactNode;
  children: ReactNode;
  open: boolean;
  closeButtonText?: string;
  classNamesSize?: string;
  onClose: () => void;
}

export const Modal = ({
  open,
  title,
  children,
  description,
  onClose,
  classNamesSize = 'sm:my-8 sm:w-full sm:max-w-xl sm:p-6',
}: Props) => {
  useEffect(() => {
    if (!open) {
      return;
    }

    const timeoutId = setTimeout(() => {
      makeNotificationsClickableWithOpenedModal();
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [open]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={classNames(
                  'relative transform rounded-lg bg-white p-4 text-left shadow-xl transition-all',
                  title ? 'pt-5' : 'pt-4',
                  classNamesSize,
                )}
              >
                <div className="absolute right-2 top-1 text-gray-500">
                  <i className="fas fa-times cursor-pointer" onClick={onClose} />
                </div>
                {title && <DialogTitle className="mb-psc-base">{title}</DialogTitle>}
                {description && (
                  <Description className={classNames(title ? 'mb-psc-base' : '')}>
                    {description}
                  </Description>
                )}
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const makeNotificationsClickableWithOpenedModal = () => {
  const rootNode = document.getElementById('root');
  if (rootNode) {
    (rootNode as any).inert = false;
  }
};
