import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode, useEffect } from 'react';

import { classNames } from '../../helpers/classNames';

interface Props {
  items: ReactNode;
  className?: string;
  classNameWidth?: string;
  classNamePosition?: string;
  buttonRender: (open: boolean) => ReactNode;
  setOpenMenu?: (value: boolean) => void;
}

export const DropdownMenu = ({ buttonRender, className, items, classNamePosition, classNameWidth, setOpenMenu }: Props) => {
  const onClick = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  return (
    <Menu as="nav" className={classNames('relative select-none', className)}>
      {({ open }) => {
        // NOTE: Below fixes a window resize glitch from @headlessui/react on menu button click.
        //       This useEffect is essentially what allows us to reset the element overflow style in "nav.tsx"
        //       Without it, we cannot properly pick up the changing element style and overwrite it.
        useEffect(() => {
          if (typeof setOpenMenu !== 'undefined') {
            setOpenMenu(open);
          }
        }, [open]);

        return (
        <Fragment>
          <MenuButton as="div" onClick={onClick}>
            {buttonRender(open)}
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems>
              <div
                className={classNames(
                  'absolute z-50 overflow-hidden rounded border border-slate-200 bg-white shadow-lg',
                  classNamePosition ? classNamePosition : 'left-0 mt-5 origin-top-left',
                  classNameWidth ? classNameWidth : 'w-64',
                )}
              >
                {items}
              </div>
            </MenuItems>
          </Transition>
        </Fragment>
      )}}
    </Menu>
  );
};
