import { Fragment, Suspense } from 'react';

import { PageContainer } from '../../../shared/client/components/page-container/page-container';
import { Loading } from '../../../shared/client/components/loading/loading';
import { useGetCurrentUser } from '../../../shared/client/hooks/use-get-current-user.hook';
import { NotificationArea } from '../../../shared/client/components/notification/notification-area';
import { useRecoilState } from 'recoil';
import { NotificationAtom } from '../../../shared/client/atoms/notification.atom';
import { Nav } from '../../../shared/client/components/nav/nav';
import { redirectToUrlIfSetInSession } from '../helpers/session.helpers';
import { Page403 } from '../../../shared/client/pages/page-403/page-403';
import { Outlet } from 'react-router-dom';
import { getMultiClientPlatformConfigForClient } from '../../../shared/client/helpers/multi-client-config.helpers';
import { useClientAppRedirect } from '../hooks/use-client-app-redirect.hook';

export const AppLogged = () => {
  const currentUser = useGetCurrentUser({});
  const [notifications, setNotifications] = useRecoilState(NotificationAtom);

  redirectToUrlIfSetInSession();
  useClientAppRedirect();

  if (currentUser.isAuthenticated === undefined) {
    return <Loading />;
  }

  const filteredRoles = currentUser?.rolesAndPermissions?.filter((role) => !!role.roleId) || [];
  const config = getMultiClientPlatformConfigForClient();

  return (
    <Suspense fallback={<Loading />}>
      {currentUser.isAuthenticated && (
        <Fragment>
          <NotificationArea
            notifications={notifications.notifications}
            onClose={(id) => {
              setNotifications((prev) => ({
                ...prev,
                notifications: prev.notifications.filter((notification) => notification.id !== id),
              }));
            }}
          />
          <Nav app="platform" customLogo={config.topNav.customLogo} />
          <PageContainer className="mt-psc-base">
            {filteredRoles.length === 0 ? <Page403 disableBackHomeButton={true} /> : <Outlet />}
          </PageContainer>
        </Fragment>
      )}
    </Suspense>
  );
};
