import PtoAiLogo from 'url:../assets/pto-ai-logo.svg';
import { MultiClientConfigKey } from '../../common/types/multi-client.types';

export interface PlatformMultiClientConfigForClient {
  topNav: {
    iconColor: string;
    customLogo?: string;
    hideAppsIcon?: boolean;
  };
  // main bar
  hideMainBarAppIcons?: boolean;
  // button primary
  buttonPrimaryClassName?: string;
}

export type PlatformMultiClientsConfigForClient = Record<MultiClientConfigKey, PlatformMultiClientConfigForClient>;

export const PlatformMultiClientConfigForClient: PlatformMultiClientsConfigForClient = {
  sanofi: {
    topNav: {
      hideAppsIcon: true,
      iconColor: 'text-slate-600',
    },
  },
  nestle: {
    topNav: {
      iconColor: 'text-slate-600',
    },
  },
  payersciences: {
    topNav: {
      iconColor: 'text-slate-600',
    },
  },
  demo: {
    hideMainBarAppIcons: true,
    topNav: {
      iconColor: 'text-[#1F4388]',
      customLogo: PtoAiLogo as string,
    },
    buttonPrimaryClassName: 'primary default bg-ps-blue text-white focus:ring-ps-blue hover:bg-ps-blue-dark',
  },
};
