import { MenuItem } from '@headlessui/react';
import { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';

import { CurrentUserAtom } from '../../atoms/current-user.atom';
import { classNames } from '../../helpers/classNames';
import { checkUserHasAnyRole } from '../../../common/helpers/auth.helpers';
import { UppercaseFirstLetter } from '../../../common/types/text.types';

export interface NavIconLinkProps {
  className?: string;
  children: ReactNode;
  icon: string;
  href: string;
  openInNewTab?: boolean;
  color: 'orange' | 'blue' | 'violet' | 'green' | 'lime' | 'gray';
  requiredRole?: UppercaseFirstLetter;
  aClassName?: string;
}

export const NavIconLink = ({
  className,
  children,
  openInNewTab,
  color,
  icon,
  href,
  requiredRole,
  aClassName,
}: NavIconLinkProps) => {
  const currentUser = useRecoilValue(CurrentUserAtom);
  if (requiredRole && !checkUserHasAnyRole(currentUser, [requiredRole])) {
    return null;
  }

  return (
    <MenuItem>
      <a
        href={href}
        rel="noopener noreferrer"
        target={openInNewTab ? '_blank' : undefined}
        className={`flex cursor-pointer items-center ${className} ${aClassName ? aClassName :'px-psc-base py-psc-base pl-psc-base'}  hover:bg-gray-100 `}
      >
        <div
          className={classNames(
            'flex h-[25px] w-[25px] cursor-pointer items-center justify-center rounded-md text-xs',
            color === 'blue' && 'text-blue-700',
            color === 'orange' && 'text-orange-500',
            color === 'violet' && 'text-violet-700',
            color === 'green' && 'text-green-600',
            color === 'lime' && 'text-lime-600',
            color === 'gray' && 'text-slate-500',
          )}
        >
          <i className={`fal ${icon} text-2xl`} />
        </div>
        <div className="pl-psc-base text-sm">{children}</div>
        {openInNewTab && (
          <div className="flex-grow text-right">
            <i className="fas fa-external-link-alt ml-2 text-sm text-gray-600" />
          </div>
        )}
      </a>
    </MenuItem>
  );
};
