import { Fragment } from 'react';

import { NavDropdownSeparator } from './nav-dropdown-separator';
import { NavIconLink } from './nav-icon-link';

import { AIM_APP_ICON, CHAT_APP_ICON, PSF_APP_ICON } from '../app-logo/app-logo.constants';
import { DropdownMenu } from '../dropdown-menu/dropdown-menu';
import { Icon } from '../icon/icon';

import { classNames } from '../../helpers/classNames';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';
import { getNavAppUrl } from '../../../common/helpers/apps.helpers';
import { AIM_ROLE, CHAT_ROLE, PSF_ROLE } from '../../../common/types/roles-permissions.types';

const GA_NAV_HOME_CLASS = 'ga_nav_home';
const GA_NAV_AIM_CLASS = 'ga_nav_aim';
const GA_NAV_CHAT_CLASS = 'ga_nav_chat';
const GA_NAV_PSF_CLASS = 'ga_nav_psf';

interface Props {
  setOpenMenu: (value: boolean) => void;
}

export const NavAppsMenu = ({ setOpenMenu }: Props) => {
  const config = getMultiClientPlatformConfigForClient();

  if (config.hideMainBarAppIcons) {
    return null;
  }

  return (
    <DropdownMenu
      classNamePosition="left-0 origin-top-left mt-5"
      setOpenMenu={setOpenMenu}
      buttonRender={(open) => (
        <Icon
          icon="fad fa-grid-2 fa-xl"
          className={classNames(
            'text-blue-900 hover:bg-blue-100',
            open ? 'bg-slate-200 text-slate-700' : config.topNav.iconColor,
          )}
        />
      )}
      items={
        <Fragment>
          <div>
            <a
              href={window.env.PLATFORM_URL}
              className={`
                flex items-center rounded px-psc-base py-psc-lg font-medium
                text-slate-900 hover:bg-gray-100 ${GA_NAV_HOME_CLASS}
              `}
            >
              <div className="flex h-[25px] w-[25px] items-center justify-center rounded-md text-xs text-orange-500">
                <i className="fad fa-home text-2xl" />
              </div>
              <span className="pl-psc-base text-sm text-gray-800">Home</span>
            </a>
          </div>
          <NavDropdownSeparator />
          <div className="py-psc-sm">
            <div className="pb-psc-sm pl-psc-base pt-psc-sm text-xs font-medium uppercase leading-none text-gray-800">
              Apps
            </div>
            <NavIconLink
              color="orange"
              icon={AIM_APP_ICON}
              requiredRole={AIM_ROLE}
              href={getNavAppUrl('aim')}
              className={GA_NAV_AIM_CLASS}
            >
              AIM
            </NavIconLink>
            <NavIconLink
              color="lime"
              icon={CHAT_APP_ICON}
              requiredRole={CHAT_ROLE}
              href={getNavAppUrl('chat')}
              className={GA_NAV_CHAT_CLASS}
            >
              Access Insights
            </NavIconLink>
            <NavIconLink
              color="lime"
              icon={PSF_APP_ICON}
              requiredRole={PSF_ROLE}
              href={getNavAppUrl('psf')}
              className={GA_NAV_PSF_CLASS}
            >
              PSF
            </NavIconLink>
          </div>
        </Fragment>
      }
    />
  );
};
