import { UppercaseFirstLetter } from './text.types';

// ADMIN
export const ADMIN_ROLE: UppercaseFirstLetter = 'Admin';

// CHAT
export const CHAT_ROLE: UppercaseFirstLetter = 'AccessInsights';

// AIM
export const AIM_ROLE: UppercaseFirstLetter = 'Aim';
export const AIM_ADMIN_ROLE: UppercaseFirstLetter = 'AdminAim';

// PSF
export const PSF_ROLE: UppercaseFirstLetter = 'Psf';
export const PSF_ADMIN_ROLE: UppercaseFirstLetter = 'AdminPsf';
export const PSF_ACCOUNT_DIRECTOR_ROLE: UppercaseFirstLetter = 'PsfAccountDirector';
