import { Fragment, ReactNode } from 'react';

import { DropdownMenu } from '../dropdown-menu/dropdown-menu';

import { classNames } from '../../helpers/classNames';
import { getMultiClientPlatformConfigForClient } from '../../helpers/multi-client-config.helpers';

const GA_ADMIN_ICON_CLASS = 'ga_nav_admin';

interface Props {
  appName: string;
  links: ReactNode;
  setOpenMenu: (value: boolean) => void;
}

export const NavAppAdminsMenu = ({ appName, links, setOpenMenu }: Props) => {
  const config = getMultiClientPlatformConfigForClient();

  return (
    <DropdownMenu
      classNamePosition="right-0 origin-top-right mt-5"
      setOpenMenu={setOpenMenu}
      buttonRender={(open) => (
        <div
          className={classNames(
            'flex h-[35px] w-[35px] cursor-pointer items-center justify-center rounded-md',
            'hover:bg-blue-100',
            open ? 'bg-slate-200 text-slate-700' : config.topNav.iconColor,
            'mr-psc-sm',
            GA_ADMIN_ICON_CLASS,
          )}
        >
          <i className="fas fa-cog fa-xl" />
        </div>
      )}
      items={
        <Fragment>
          <div className="mb-psc-sm">
            <div className="pb-psc-sm pl-psc-base pt-psc-base text-xs font-medium uppercase leading-none text-gray-800">
              {appName} Admin
            </div>
            {links}
          </div>
        </Fragment>
      }
    />
  );
};
